<div class="d-flex align-items-center profile">
  <button #button class="p-0 btn" (click)="open(content)">
    <fa-icon
      class="d-none d-md-flex align-items-center justify-content-center"
      [icon]="['fas', 'user']"
      size="xl"
    ></fa-icon>
    <fa-icon class="d-md-none" [icon]="['fas', 'user']" [transform]="'grow-4'"></fa-icon>
  </button>
</div>

<ng-template #content let-offcanvas>
  <div class="profile-menu-wrapper">
    <div class="offcanvas-header">
      <div class="d-flex flex-column">
        <div>{{ 'NAME' | translate }}</div>
        <div>{{ (user$ | async)?.firstName }} {{ (user$ | async)?.lastName }}</div>
      </div>

      <div class="d-flex flex-column">
        <div>{{ 'EMAIL' | translate }}</div>
        <div>{{ (user$ | async)?.email }}</div>
      </div>
    </div>
    <div class="offcanvas-body main">
      <div class="d-flex flex-column">
        <div class="h-100 d-flex align-items-center">
          <a class="logout" (click)="logOut()">
            <span>{{ 'Log out' | translate }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-template>
