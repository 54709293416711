import { Component, Input, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import {
  AuthStoreService,
  DialogNgbService,
  MxLoggerService,
  Content,
  ContentDynamicLayout,
} from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ValidatorsUtil } from 'src/app/core/util/validators.util';

@UntilDestroy()
@Component({
  selector: 'app-forgot-password-content-template',
  templateUrl: './forgot-password-content-template.component.html',
  styleUrls: ['./forgot-password-content-template.component.scss'],
})
export class ForgotPasswordContentTemplateComponent implements OnInit, ContentDynamicLayout {
  @Input() baseContent: Content;
  background: string;
  olympicsDesktopVideo =
    'https://mmicdn.s3.eu-central-1.amazonaws.com/NutanixRaceToOlympics/Video/Nutanix_Winter_Olympics-desktop.mp4';

  olympicsTabletVideo =
    'https://mmicdn.s3.eu-central-1.amazonaws.com/NutanixRaceToOlympics/Video/Nutanix_Winter_Olympics-tablet.mp4';

  olympicsMobileVideo =
    'https://mmicdn.s3.eu-central-1.amazonaws.com/NutanixRaceToOlympics/Video/Nutanix_Winter_Olympics-tablet.mp4';

  olympicsIndigoDesktopLogo =
    'https://mmicdn.s3.eu-central-1.amazonaws.com/NutanixRaceToOlympics/Logo/Race-to-the-Olympics-logo-indigo_Desktop_72dpi.png';

  olympicsIndigoTabletLogo =
    'https://mmicdn.s3.eu-central-1.amazonaws.com/NutanixRaceToOlympics/Logo/Race-to-the-Olympics-logo-indigo_Tablet_72dpi.png';

  olympicsIndigoMobileLogo =
    'https://mmicdn.s3.eu-central-1.amazonaws.com/NutanixRaceToOlympics/Logo/Race-to-the-Olympics-logo-indigo_Mobile_72dpi.png';

  verificationSent = false;

  formGroup = this.formBuilder.group({
    email: [null, [Validators.required]],
    verificationCode: [null, [ValidatorsUtil.conditionalValidator(() => this.verificationSent, Validators.required)]],
    newPassword: [
      null,
      [
        ValidatorsUtil.conditionalValidator(() => this.verificationSent, Validators.required),
        Validators.pattern('^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[^\\w\\d])(?=.*\\d).*$'),
      ],
    ],
    confirmedPassword: [null, [ValidatorsUtil.conditionalValidator(() => this.verificationSent, Validators.required)]],
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authStore: AuthStoreService,
    private dialogService: DialogNgbService,
    private mxLogger: MxLoggerService
  ) {}

  ngOnInit(): void {
    const backgroundField = this.baseContent.fields.find((f) => f.name === 'Background');
    this.background = backgroundField!.value.url;

    this.authStore.forgotPasswordCodeSent$.pipe(untilDestroyed(this)).subscribe((codeSent) => {
      this.verificationSent = codeSent;
      this.formGroup.updateValueAndValidity();
    });
  }

  onSubmit(): void {
    const formValue = this.formGroup.value;

    if (!this.verificationSent) {
      if (this.formGroup.valid) {
        this.authStore.sendForgotPasswordCode(formValue.email);
      } else {
        this.dialogService.openError(['Please enter a valid Member Id.'], 'Important');
      }
    } else if (this.formGroup.valid) {
      if (formValue.newPassword === formValue.confirmedPassword) {
        this.authStore.submitForgotPassword(formValue.email, formValue.verificationCode, formValue.newPassword);
      } else {
        this.dialogService.openError(['Passwords do not match.'], 'Important');
        this.mxLogger.trace('ForgotPasswordContentTemplateComponent', 'Passwords do not match.');
      }
    } else {
      if (this.formGroup.controls['verificationCode'].errors) {
        this.dialogService.openError(['You need to enter verification code.'], 'Important');
      }
      if (this.formGroup.controls['newPassword'].errors) {
        const passwordRequirements =
          // eslint-disable-next-line prettier/prettier
          'The password needs to be at least 8 characters long and contain 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character from the following = + - ^ $ * . [ ] { } ( ) ? ! @ # % & / \\ , > \' : ; | _ ~ `.';

        this.dialogService.openError([passwordRequirements], 'Important');
      } else if (this.formGroup.controls['confirmedPassword'].errors) {
        this.dialogService.openError(['You need to enter Confirm Password.'], 'Important');
      }

      this.mxLogger.trace('ForgotPasswordContentTemplateComponent', 'The form is invalid.');
    }
  }
}
