<div class="forgot-password-wrapper">
  <div class="p-0 position-relative container-fluid background">
    <div class="d-none d-xl-block video-wrapper">
      <video class autoplay muted loop>
        <source [src]="olympicsDesktopVideo" type="video/mp4" />
      </video>
    </div>
    <div class="d-none d-md-block d-xl-none video-wrapper">
      <video autoplay muted loop>
        <source [src]="olympicsTabletVideo" type="video/mp4" />
      </video>
    </div>
    <div class="d-md-none video-wrapper">
      <video autoplay muted loop>
        <source [src]="olympicsMobileVideo" type="video/mp4" />
      </video>
    </div>
    <div class="position-relative container">
      <div class="row">
        <div class="col-12 col-xl-7">
          <div class="p-4 pt-lg-5 form-wrapper">
            <div class="mt-4 mt-xl-0 w-100 d-flex justify-content-center">
              <img class="d-none d-xl-block" [src]="olympicsIndigoDesktopLogo" />
              <img class="d-none d-md-block d-xl-none" [src]="olympicsIndigoTabletLogo" />
              <img class="d-md-none" [src]="olympicsIndigoMobileLogo" />
            </div>
            <p class="text-center mt-5 mb-3" *ngIf="!verificationSent">
              Enter your Email Address and then click <br class="d-lg-none" />the 'Get verification code' button.<br />
              You will receive an email with the code to reset your password.
            </p>
            <form
              [formGroup]="formGroup"
              class="d-flex flex-column align-items-center justify-content-center w-100"
              (ngSubmit)="onSubmit()"
            >
              <div class="form-row p-1 col-11 d-block" *ngIf="!verificationSent">
                <label class="fw-bold">Email</label>
                <input
                  id="email"
                  type="text"
                  formControlName="email"
                  placeholder="Email Address"
                  class="w-100 form-control"
                />
              </div>
              <div class="form-row p-1 mt-5 col-11 d-block" *ngIf="verificationSent">
                <label class="fw-bold">Verification code</label>
                <input
                  id="verificationCode"
                  type="text"
                  formControlName="verificationCode"
                  placeholder="Verification code"
                  class="w-100 form-control"
                />
              </div>
              <div class="form-row p-1 col-11 d-block" *ngIf="verificationSent">
                <label class="fw-bold">New password</label>
                <input
                  id="newPassword"
                  type="password"
                  formControlName="newPassword"
                  placeholder="New password"
                  class="w-100 form-control"
                />
                <p class="mt-2">
                  The password needs to be at least 8 characters long and contain 1 uppercase letter, 1 lowercase
                  letter, 1 number and 1 special character from the following = + - ^ $ * . [ ] ( ) ? ! @ # % & / \ , >
                  ' : ; | _ ~ `.
                </p>
              </div>
              <div class="form-row p-1 col-11 d-block" *ngIf="verificationSent">
                <label class="fw-bold">Confirm password</label>
                <input
                  id="confirmedPassword"
                  type="password"
                  formControlName="confirmedPassword"
                  placeholder="Confirm password"
                  class="w-100 form-control"
                />
              </div>
              <div class="form-row w-75 pt-4 d-block">
                <div class="d-flex flex-column align-items-center justify-content-center w-100">
                  <button class="btn btn-primary btn-round btn-forgot" type="submit">
                    {{ verificationSent ? 'Reset Password' : 'Get Verification Code' }}
                  </button>
                  <a class="mt-3" routerLink="/auth/login">Back to Login</a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
