import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  AuthGuard,
  LoginImpersonateContainerComponent,
  LoginRedirectContainerComponent,
  UnauthGuard,
} from '@motivforce/mx-library-angular';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/landing',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('@motivforce/mx-library-angular').then((m) => m.ContentModule),
    canActivate: [UnauthGuard],
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('@motivforce/mx-library-angular').then((m) => m.ContentModule),
    canActivate: [UnauthGuard],
  },
  {
    path: 'set-password',
    loadChildren: () => import('@motivforce/mx-library-angular').then((m) => m.ContentModule),
    canActivate: [UnauthGuard],
  },
  {
    path: 'redirect',
    component: LoginRedirectContainerComponent,
    canActivate: [],
    data: {
      hideHeaderFooter: true,
    },
  },
  {
    path: 'impersonate',
    component: LoginImpersonateContainerComponent,
    canActivate: [AuthGuard],
    data: {
      hideHeaderFooter: true,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
