<div class="login-wrapper">
  <div class="p-0 position-relative container-fluid background">
    <div class="d-none d-xl-block video-wrapper">
      <video class autoplay muted loop>
        <source [src]="olympicsDesktopVideo" type="video/mp4" />
      </video>
    </div>
    <div class="d-none d-md-block d-xl-none video-wrapper">
      <video autoplay muted loop>
        <source [src]="olympicsTabletVideo" type="video/mp4" />
      </video>
    </div>
    <div class="d-md-none video-wrapper">
      <video autoplay muted loop>
        <source [src]="olympicsMobileVideo" type="video/mp4" />
      </video>
    </div>
    <div class="position-relative container">
      <div class="row">
        <div class="col-12 col-xl-7">
          <div class="p-4 pt-lg-5 form-wrapper">
            <div class="mt-4 mt-xl-0 w-100 d-flex justify-content-center mb-4">
              <img
                class="d-none d-xl-block"
                style="width: 29rem; height: 7.3rem; margin-bottom: 1rem"
                [src]="olympicsIndigoDesktopLogo"
              />
              <img class="d-none d-md-block d-xl-none" [src]="olympicsIndigoTabletLogo" />
              <img class="d-md-none" [src]="olympicsIndigoMobileLogo" />
            </div>

            <form [formGroup]="formGroup">
              <div class="mb-3 col-12 d-flex flex-column align-items-start">
                <label class="fw-bold">Email</label>
                <input class="form-control" type="email" formControlName="email" autocomplete="off" />
              </div>
              <div class="mb-3 col-12 d-flex flex-column align-items-start">
                <label class="fw-bold">Password</label>
                <input class="form-control" type="password" formControlName="password" autocomplete="off" />
              </div>
            </form>
            <div class="mt-3 d-flex flex-column align-items-md-center align-items-start">
              <button type="submit" class="btn btn-primary btn-round" (click)="onSubmit()">Login</button>
              <a class="mt-3" routerLink="/auth/forgot-password">Forgot Password</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
